<template>
	<div class="main-home">
		<el-row :gutter="20">
			<el-col :span="7">
				<div>
					<div class="title-box1"><span class="m-title">充值金额统计</span></div>
					<div class="content-box">
						<!-- <el-row class="rowone">
							<el-col :span="6" class="col-flex">
								<p style="color: #09A2E3" id="rechargeAmountTotal">{{data.rechargeAmountTotal}}</p>
								<p>充值总金额</p>
							</el-col>
							<el-col :span="6" class="col-flex">
								<p style="color: #09A2E3" id="rechargeAmountThisMonth">{{data.rechargeAmountThisMonth}}
								</p>
								<p>本月充值</p>
							</el-col>
							<el-col :span="6" class="col-flex">
								<p style="color: #09A2E3" id="rechargeAmountPreMonth">{{data.rechargeAmountPreMonth}}
								</p>
								<p>上月充值</p>
							</el-col>
							<el-col :span="6" class="col-flex">
								<p style="color: #09A2E3" id="rechargeAmountYestoday">{{data.rechargeAmountYestoday}}
								</p>
								<p>昨日充值</p>
							</el-col>
						</el-row> -->
						<div class="rowone">
							<p>充值总金额</p>
							<p style="color: #09A2E3" id="rechargeAmountTotal">{{data.rechargeAmountTotal}}</p>
						</div>
						<div class="rowone">
							<p>本月充值</p>
							<p style="color: #09A2E3" id="rechargeAmountThisMonth">{{data.rechargeAmountThisMonth}}
							</p>
						</div>

						<div class="rowone">
							<p>上月充值</p>
							<p style="color: #09A2E3" id="rechargeAmountPreMonth">{{data.rechargeAmountPreMonth}}
							</p>

						</div>

						<div class="rowone">
							<p>昨日充值</p>
							<p style="color: #09A2E3" id="rechargeAmountYestoday">{{data.rechargeAmountYestoday}}
							</p>
						</div>





					</div>
				</div>
			</el-col>
			<el-col :span="7">
				<div>
					<div class="title-box1"><span class="m-title">订单统计</span></div>
					<div class="content-box">
						<!-- <el-row class="rowone">
							<el-col :span="6" class="col-flex">
								<p style="color: #09A2E3" id="orderCountTotal">{{data.orderCountTotal}}</p>
								<p>总订单</p>
							</el-col>
							<el-col :span="6" class="col-flex">
								<p style="color: #09A2E3" id="orderCountThisMonth">{{data.orderCountThisMonth}}</p>
								<p>本月订单</p>
							</el-col>
							<el-col :span="6" class="col-flex">
								<p style="color: #09A2E3" id="orderCountPreMonth">{{data.orderCountPreMonth}}</p>
								<p>上月订单</p>
							</el-col>
							<el-col :span="6" class="col-flex">
								<p style="color: #09A2E3" id="orderCountYesterday">{{data.orderCountYesterday}}</p>
								<p>昨日订单</p>
							</el-col>
						</el-row> -->
						<div class="rowone">
							<p>总订单</p>
							<p style="color: #09A2E3" id="orderCountTotal">{{data.orderCountTotal}}</p>

						</div>
						<div class="rowone">
							<p>本月订单</p>
							<p style="color: #09A2E3" id="orderCountThisMonth">{{data.orderCountThisMonth}}</p>

						</div>
						<div class="rowone">
							<p>上月订单</p>
							<p style="color: #09A2E3" id="orderCountPreMonth">{{data.orderCountPreMonth}}</p>

						</div>
						<div class="rowone">
							<p>昨日订单</p>
							<p style="color: #09A2E3" id="orderCountYesterday">{{data.orderCountYesterday}}</p>

						</div>

					</div>
				</div>
			</el-col>
			<el-col :span="7">
				<div>
					<div class="title-box1"><span class="m-title">代理商统计</span></div>
					<div class="content-box">
						<!-- <el-row class="rowone">
							<el-col :span="8" class="col-flex">
								<p style="color: #09A2E3" id="agentCount">{{data.agentCount}}</p>
								<p>代理商总数</p>
							</el-col>
							<el-col :span="8" class="col-flex">
								<p style="color: #09A2E3" id="agentCountThisMonth">{{data.agentCountThisMonth}}</p>
								<p>本月新增代理商</p>
							</el-col>
							<el-col :span="8" class="col-flex">
								<p style="color: #09A2E3" id="agentCountDisabled">{{data.agentCountDisabled}}</p>
								<p>禁用代理商数</p>
							</el-col>

						</el-row> -->
						<div class="rowone">
							<p>代理商总数</p>
							<p style="color: #09A2E3" id="agentCount">{{data.agentCount}}</p>

						</div>
						<div class="rowone">
							<p>本月新增代理商</p>
							<p style="color: #09A2E3" id="agentCountThisMonth">{{data.agentCountThisMonth}}</p>

						</div>
						<div class="rowone">
							<p>禁用代理商数</p>
							<p style="color: #09A2E3" id="agentCountDisabled">{{data.agentCountDisabled}}</p>

						</div>


					</div>
				</div>
			</el-col>
			<el-col :span="3">
				<div>
					<div class="title-box1"><span class="m-title">更多统计</span></div>
					<div class="content-box">
						<el-row class="rowone">
							<el-col :span="24" class="col-flex">
								<a href="javascript:;" @click="seeMore">点击查看</a>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="7">
				<div>
					<div class="title-box1"><span class="m-title">网卡使用统计</span></div>
					<div class="content-box">
						<el-row :gutter="20">
							<el-col :span="16">
								<div id="pirMainId" style=";height: 200px;"></div>
							</el-col>
							<el-col :span="8">
								<div style="margin-top: 26px;">
									<div class="tip-main">
										<div class="tip-view" style="background-color: #00b395;"></div>
										<div class="inline ml10">
											<span class="tip-text1">正常使用</span><br />
											<span class="tip-text2" id="cardUseNormal">{{data.cardUseNormal}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #00c6c7;"></div>
										<div class="inline ml10">
											<span class="tip-text1">停机/断网</span><br />
											<span class="tip-text2" id="cardUseStop">{{data.cardUseStop}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #c0c9cd;"></div>
										<div class="inline ml10">
											<span class="tip-text1">超流量卡</span><br />
											<span class="tip-text2" id="cardUseOverflow">{{data.cardUseOverflow}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #caaddd;"></div>
										<div class="inline ml10">
											<span class="tip-text1">异常用卡</span><br />
											<span class="tip-text2" id="cardUseAbnormal">{{data.cardUseAbnormal}}</span>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
			<el-col :span="7">
				<div>
					<div class="title-box1"><span class="m-title">网卡统计</span></div>
					<div class="content-box">
						<el-row :gutter="20">
							<el-col :span="16">
								<div id="pirMainId2" style=";height: 200px;"></div>
							</el-col>
							<el-col :span="8">
								<div style="margin-top: 26px;">
									<div class="tip-main">
										<div class="tip-view" style="background-color: #00b395;"></div>
										<div class="inline ml10">
											<span class="tip-text1">网卡总数</span><br />
											<span class="tip-text2" id="cardStatisTotal">{{data.cardStatisTotal}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #fe941a;"></div>
										<div class="inline ml10">
											<span class="tip-text1">已分销</span><br />
											<span class="tip-text2" id="cardStatisDist">{{data.cardStatisDist}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #5ebbdd;"></div>
										<div class="inline ml10">
											<span class="tip-text1">未分销</span><br />
											<span class="tip-text2"
												id="cardStatisUndist">{{data.cardStatisUndist}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color:#ff5643;"></div>
										<div class="inline ml10">
											<span class="tip-text1">已失效</span><br />
											<span class="tip-text2"
												id="cardStatisInvalid">{{data.cardStatisInvalid}}</span>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>


					</div>
				</div>
			</el-col>
			<el-col :span="7">
				<div>
					<div class="title-box1"><span class="m-title"
							id="liuliang_name">{{"流量使用(GB)-"+data1.accountName}}</span></div>
					<div class="content-box">
						<el-row :gutter="20">
							<el-col :span="16">
								<div id="liuliang_pie" style=";height: 200px;"></div>
							</el-col>
							<el-col :span="8">
								<div style="margin-top: 26px;">
									<div class="tip-main">
										<div class="tip-view" style="background-color: #00b395;"></div>
										<div class="inline ml10">
											<span class="tip-text1">总流量</span><br />
											<span class="tip-text2" id="flowSizeTotal">{{totalFlow}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #5ebbdd;"></div>
										<div class="inline ml10">
											<span class="tip-text1">已用流量</span><br />
											<span class="tip-text2"
												id="flowSizeUsed">{{(data1.flowSizeUsed / 1024).toFixed(0)}}</span>
										</div>
									</div>
									<div class="tip-main">
										<div class="tip-view" style="background-color: #97c407"></div>
										<div class="inline ml10">
											<span class="tip-text1">剩余流量</span><br />
											<span class="tip-text2"
												id="flowSizeRemaining">{{(data1.flowSizeRemaining / 1024).toFixed(0)}}</span>
										</div>
									</div>

								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
			<el-col :span="3">
				<div>
					<div class="title-box1"><span class="m-title">更多统计</span></div>
					<div class="content-box">
						<el-row class="rowone">
							<el-col :span="24" class="col-flex">
								<a href="javascript:;" @click="seeMorePool">点击查看</a>
							</el-col>
						</el-row>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="16">
				<div class="col3">
					<div class="title-box1"><span class="m-title">近7日流量池使用情况(GB)</span></div>
					<div id="totalPoolStatis" style="height:500px;background-color: #fff;"></div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="col3">
					<div class="title-box1"><span class="m-title">昨日流量使用排名
							<!-- <a style="margin-left: 10px; color: #6a6a6a;" href="javascript:;" @click="openCardDayFlow">查看更多</a> -->
						</span></div>
					<el-table :data="tableData" ref='table' style="width: 100%;height: 500px;">
						<el-table-column prop="date" label="排名" width="50">
							<template v-slot:default="scope">
								{{"第"+(scope.$index+1)+"名"}}
							</template>
						</el-table-column>
						<el-table-column prop="iccidMark" label="卡号" width="120">
						</el-table-column>
						<el-table-column prop="usedFlowGB" label="已使用量GB">
						</el-table-column>
					</el-table>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="16">
				<div class="col3">
					<div class="title-box1"><span class="m-title">近7日订单数</span></div>
					<div id="chartMainId" style="height:500px;background-color: #fff;"></div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="col3">
					<div class="title-box1"><span class="m-title">套餐销售前7排行榜<a style="margin-left: 10px; color: #6a6a6a;"
								href="javascript:;" @click="mealseeMore">查看更多</a></span></div>
					<el-table :data="tableData1" ref='table' style="width: 100%;height: 500px;overflow: auto;" border>
						<el-table-column prop="date" label="排名" width="80">
							<template v-slot:default="scope">
								{{"第"+(scope.$index+1)+"名"}}
							</template>
						</el-table-column>
						<el-table-column prop="mealName" label="套餐" width="120">
						</el-table-column>
						<el-table-column prop="" label="运营商">
							<template v-slot:default="scope">
								<!-- {{getCategory(scope.row.category)}} -->
								{{categoryString(scope.row.category)}}
								
							</template>
						</el-table-column>
						<el-table-column prop="" label="套餐流量(G)" width="120">
							<template v-slot:default="scope">
								{{ parseFloat(scope.row.flowSize/1024).toFixed(0) }}
							</template>
						</el-table-column>
						<el-table-column prop="orderCount" label="本月订单数">
						</el-table-column>
					</el-table>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="16">
				<div class="col3">
					<div class="title-box1"><span class="m-title">前十代理商销售榜单</span></div>
					<div id="chartMainId3" style="height:500px;background-color: #fff;"></div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="col3">
					<div class="title-box1"><span class="m-title">前十代理商销售榜单</span></div>
					<el-table :data="tableData2" ref='table' style="width: 100%;height: 500px;overflow: auto;">
						<el-table-column prop="createDatetime" label="日期" width="120">
						</el-table-column>
						<el-table-column prop="date" label="排名" width="120">
							<template v-slot:default="scope">
								{{"第"+(scope.$index+1)+"名"}}
							</template>
						</el-table-column>
						<el-table-column prop="agentName" label="姓名" width="120">
						</el-table-column>
						<el-table-column prop="orderCount" label="当日订单数">
						</el-table-column>
						<el-table-column prop="orderMoney" label="当日订单金额" width="120">
						</el-table-column>

					</el-table>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="16">
				<div class="col3">
					<div class="title-box1"><span class="m-title">用户分布</span></div>
					<div id="china-map" style="height:500px;background-color: #fff;"></div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="col3">
					<div class="title-box1"><span class="m-title">用户数量TOP10<a style="margin-left: 10px; color: #6a6a6a;"
								href="javascript:;"></a></span></div>
					<el-table :data="tableData3" ref='table' style="width: 100%;height: 500px;">

						<el-table-column prop="date" label="排名" width="50">
							<template v-slot:default="scope">
								{{"第"+(scope.$index+1)+"名"}}
							</template>
						</el-table-column>
						<el-table-column prop="key" label="省份" width="120">
						</el-table-column>
						<el-table-column prop="orderAmount" label="总用户数">
							<template v-slot="scope">
								{{scope.row.value.count}}
							</template>
						</el-table-column>
						<el-table-column prop="" label="电信用户数" width="120">
							<template v-slot="scope">
								{{scope.row.value.dxCount}}
							</template>
						</el-table-column>
						<el-table-column prop="" label="联通用户数" width="120">
							<template v-slot="scope">
								{{scope.row.value.ltCount}}
							</template>
						</el-table-column>
						<el-table-column prop="" label="移动用户数" width="120">
							<template v-slot="scope">
								{{scope.row.value.ydCount}}
							</template>
						</el-table-column>

					</el-table>
				</div>
			</el-col>
		</el-row>

		<el-row :gutter="20" style="margin-top: 20px;">
			<el-col :span="24">
				<div class="col3">
					<div class="title-box1"><span class="m-title">历史月充值额统计</span><el-button @click="exportMonthRecharge" size="mini" class="themed-button right-button" type="danger">导 出</el-button></div>
					<div id='monthRecharge' style="height:500px;background-color: #fff;"></div>
				</div>
			</el-col>
			
		</el-row>

	</div>
</template>

<script>
	import echarts from "echarts"
	import {
		OrderList,
		list4main,
		list4order,
		agentTrankList,
		list4pool,
		statisGps,
		getStaticsAdmin,
		staticsAdminExport,
	} from "@/api/welcome.js"
	import dictGroup from '@/mixins/dictGroup.js'
	export default {
		mixins:[dictGroup],
		data() {
			return {
				tableData3: [],
				tableData2: [],
				tableData1: [],
				tableData: [],
				data: {},
				data1: {
					flowSizeUsed: "",
					flowSizeRemaining: "",
					accountName: ""
				},
				pieChart1: "",
				pieChart2: "",
				chartMainId: "",
				chartMainId3: "",
				liuliang_pie: "",
				totalPoolStatis: "",
				titleArr: [],
				valueArr: [],
				titleArr1: [],
				valueArr1: [],
				titleArr2: [],
				valueArr2: [],
				monthRechargeChart:"",
			}
		},
		created() {
				 if(this.$store.getters.categorys.length<1){
                this.$store.dispatch('getCategory')
        }
			this.getTop7List()
			this.getList4main()
			this.getList4order()
			this.getAgentTrankList()
			this.getList4pool()
			this.getStatisGps()
			this.getMonthRecharge()
		
		},
		mounted() {
			window.onresize = () => {
				return (() => {
					this.pieChart1.resize()
					this.pieChart2.resize()
					this.liuliang_pie.resize()
					this.totalPoolStatis.resize()
					this.monthRechargeChart.resize()
					if (!!this.chartMainId) {
						this.chartMainId.resize()
					}
					if (!!this.chartMainId3) {
						this.chartMainId3.resize()
					}

				})()
			}
		},
		computed: {
			totalFlow() {
				let totalFlow = 0
				if (this.data1 != null && this.data1.category === 3) {//category无等于3的情况
					totalFlow = (this.data1.cardCountChanrged || 0) * 11;
				} else {
					totalFlow = (this.data1.cardCountChanrged || 0) * 10;
				}
				return totalFlow
			},
			seriesData() {
				let seriesData = []
				var provinces = ['shanghai', 'hebei', 'shanxi', 'neimenggu', 'liaoning', 'jilin', 'heilongjiang',
					'jiangsu', 'zhejiang', 'anhui', 'fujian', 'jiangxi', 'shandong', 'henan', 'hubei', 'hunan',
					'guangdong', 'guangxi', 'hainan', 'sichuan', 'guizhou', 'yunnan', 'xizang', 'shanxi1', 'gansu',
					'qinghai', 'ningxia', 'xinjiang', 'beijing', 'tianjin', 'chongqing', 'xianggang', 'aomen'
				];
				var provincesText = ['上海', '河北', '山西', '内蒙古', '辽宁', '吉林', '黑龙江', '江苏', '浙江', '安徽', '福建', '江西', '山东', '河南',
					'湖北', '湖南', '广东', '广西', '海南', '四川', '贵州', '云南', '西藏', '陕西', '甘肃', '青海', '宁夏', '新疆', '北京', '天津',
					'重庆', '香港', '澳门'
				];
				if (this.tableData3) {
					var idx = 6;
					for (let i in this.tableData3) {
						var item = this.tableData3[i]
						var name = '';
						for (let provin of provincesText) {
							let pro = item.key
							if (pro.indexOf(provin) != -1) {
								name = provin
								break;
							}
						}
						seriesData.push({
							name: name,
							value: i < 7 ? idx-- : 0,
							count: item.value.count,
							dxCount: item.value.dxCount,
							ltCount: item.value.ltCount,
							ydCount: item.value.ydCount,

						});
					}
				}
				return seriesData
			},

		},
		methods: {
			categoryString(category){
            let cuCatagorys
            if(this.$store.getters.categorys.length>0){
               this.$store.getters.categorys.forEach(item=>{
                   if(category==item.value){
                     cuCatagorys = item.name
                   }
               })
            }
            return cuCatagorys
        },
			seeMore() {
				this.$router.push("/home/moreStatics")
			},
			seeMorePool() {
				this.$router.push("/home/statics_pool_more")
			},
			mealseeMore() {
				this.$router.push("/staticsManagement/packageSalesRecord")
			},
			openCardDayFlow() {
				this.$router.push("/home/cardDayFlow")
			},
			getTop7List() {
				OrderList({
					queryDate: 1
				}).then(res => {
					this.tableData1 = res.rows
				})
			},
			// getCategory(type) {
			// 	let categoryStr = "";
			// 	if (type == 1) {
			// 		categoryStr = "电信";
			// 	} else if (type == 3) {
			// 		categoryStr = "移动";
			// 	} else if (type == 4) {
			// 		categoryStr = "联通";
			// 	} else if (type == 5) {
			// 		categoryStr = "DCP电信";
			// 	} else if (type == 6) {
			// 		categoryStr = "联通Q";
			// 	} else if (type == 7) {
			// 		categoryStr = "新移动";
			// 	}
			// 	return categoryStr
			// },
			getList4main() {
				list4main({}).then(res => {
					this.data = res.entity
					this.loadPie1(res.entity.cardUseNormal, res.entity.cardUseStop, res.entity.cardUseOverflow, res
						.entity.cardUseAbnormal);
					this.loadPie2(res.entity.cardStatisTotal, res.entity.cardStatisDist, res.entity
						.cardStatisUndist, res.entity.cardStatisInvalid);
				})
			},
			loadPie1(nomarlCount, disableCount, overflowCount, abnormalCount) {
				var dom = document.getElementById("pirMainId");
				var myChart = echarts.init(dom);
				this.pieChart1 = myChart
				const option = {
					tooltip: {
						trigger: 'item',
						formatter: "{a} <br/>{b}: {c} ({d}%)"
					},
					legend: {
						show: false
					},
					series: [{
						color: ["#00b395", "#00c6c7", "#c0c9cd", "#caaddd"],
						name: '网卡使用统计',
						type: 'pie',
						radius: ['50%', '75%'],
						avoidLabelOverlap: false,
						label: {
							normal: {
								show: false,
								position: 'center'
							},
							emphasis: {
								show: true,
								textStyle: {
									fontSize: '14',
									fontWeight: 'bold'
								}
							}
						},
						labelLine: {
							normal: {
								show: false
							}
						},
						data: [{
								value: nomarlCount,
								name: '正常使用'
							},
							{
								value: disableCount,
								name: '停机/断网'
							},
							{
								value: overflowCount,
								name: '超流量卡'
							},
							{
								value: abnormalCount,
								name: '异常用卡'
							}
						]
					}]
				};

				if (option && typeof option === "object") {
					myChart.setOption(option, true);
				}
			},
			loadPie2(total, dist, undist, stop) {
				var dom = document.getElementById("pirMainId2");
				var myChart = echarts.init(dom);
				this.pieChart2 = myChart
				const option = {
					tooltip: {
						trigger: 'item',
						formatter: "{a} <br/>{b}: {c} ({d}%)"
					},
					legend: {
						show: false,
					},
					series: [{
						color: ["#fe941a", "#5ebbdd", "#ff5643", "#caaddd"],
						name: '网卡统计',
						type: 'pie',
						radius: ['50%', '70%'],
						avoidLabelOverlap: false,
						label: {
							normal: {
								show: false,
								position: 'center'
							},
							emphasis: {
								show: true,
								textStyle: {
									fontSize: '14',
									fontWeight: 'bold'
								}
							}
						},
						labelLine: {
							normal: {
								show: false
							}
						},
						data: [{
								value: dist,
								name: '已分销'
							},
							{
								value: undist,
								name: '未分销'
							},
							{
								value: stop,
								name: '已失效'
							}
						]
					}]
				};;
				if (option && typeof option === "object") {
					myChart.setOption(option, true);
				}
			},
			getList4order() {
				list4order({}).then(res => {
					let dataList = res.list
					this.loadChart1(dataList)
				})
			},
			loadChart1(dataList) {
				this.titleArr = []
				this.valueArr = []
				for (let i of dataList) {
					let date = new Date(i.statisDate)
					let month = date.getMonth() + 1
					let day = date.getDate()
					let dateStr = (month < 10 ? "0" + month : "" + month) + "-" + (day < 10 ? "0" + day : "" + day);
					this.titleArr.unshift(dateStr)
					this.valueArr.unshift(i.orderCount)
				}
				var dom = document.getElementById("chartMainId");
				var myChart = echarts.init(dom);
				this.chartMainId = myChart
				const option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.titleArr
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
						color: '#E0EBFD',
						lineStyle: {
							color: '#A5B6E0'
						},
						data: this.valueArr,
						type: 'line',
						areaStyle: {}
					}]
				};;
				if (option && typeof option === "object") {
					myChart.setOption(option, true);
				}
			},

			getMonthRecharge(){
				getStaticsAdmin().then(res=>{
					let amount = res.amount.reverse()
					let month = res.month.reverse()
					this.$nextTick(function(){
						this.showMonthRechargeChart(amount,month)
					})
				})
				
				
			},

			showMonthRechargeChart(amount,month){
				let dom = document.getElementById("monthRecharge");
				let myChart = echarts.init(dom)
				this.monthRechargeChart = myChart
				const option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: month
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						itemStyle: {
							normal: {
								label: {
									show: true,
									color:"#000"
								}
							}

						},
						color: '#E0EBFD',
						lineStyle: {
							color: '#A5B6E0'
						},
						data: amount,
						type: 'line',
						areaStyle: {}
					}]
				}
				if (option && typeof option === "object") {
					myChart.setOption(option, true);
				}
				
			},

			exportMonthRecharge(){
				staticsAdminExport().then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
			},

			getAgentTrankList() {
				agentTrankList({}).then(res => {
					this.tableData2 = res.rows
					//console.log(this.tableData2)
					let dataList = res.rows
					this.loadChart3(dataList)
				})
			},
			loadChart3(dataList) {
				this.titleArr1 = []
				this.valueArr1 = []
				for (let i of dataList) {
					this.titleArr1.push(i.agentName)
					this.valueArr1.push(i.orderCount)
				}
				let dom = document.getElementById("chartMainId3");
				let myChart = echarts.init(dom);
				this.chartMainId3 = myChart
				const option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.titleArr1
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
						color: '#AE0000',
						lineStyle: {
							color: '#A5B6E0'
						},
						data: this.valueArr1,
						type: 'line',
						areaStyle: {}
					}]
				};
				if (option && typeof option === "object") {
					myChart.setOption(option, true);
				}
			},
			getList4pool() {
				list4pool({
					limit: 1
				}).then(res => {
					this.data1 = res.list[0]
					console.log(this.data1);
					this.loadPie_liuliang((this.data1.flowSizeRemaining / 1024).toFixed(0), (this.data1
						.flowSizeUsed / 1024).toFixed(
						0), "流量使用(GB)-" + this.data1.accountName)
					this.tableData = res.yesterdayTopCards
					this.loadChart_totalPoolStatis(res.totalPoolStatis)
				})
			},
			loadPie_liuliang(shengyu, yishiyong, accountName) {
				let dom = document.getElementById("liuliang_pie");
				let myChart = echarts.init(dom);
				this.liuliang_pie = myChart
				const option = {
					tooltip: {
						trigger: 'item',
						formatter: "{a} <br/>{b}: {c} ({d}%)"
					},
					legend: {
						show: false,
					},
					series: [{
						color: ["#97c407", "#5ebbdd", ],
						name: accountName,
						type: 'pie',
						radius: ['50%', '70%'],
						avoidLabelOverlap: false,
						label: {
							normal: {
								show: false,
								position: 'center'
							},
							emphasis: {
								show: true,
								textStyle: {
									fontSize: '14',
									fontWeight: 'bold'
								}
							}
						},
						labelLine: {
							normal: {
								show: false
							}
						},
						data: [{
								value: shengyu,
								name: '剩余流量'
							},
							{
								value: yishiyong,
								name: '已用流量'
							}
						]
					}]
				};

				if (option && typeof option === "object") {
					myChart.setOption(option, true);
				}
			},
			loadChart_totalPoolStatis(dataList, yesterdayTopCards) {
				this.titleArr2 = []
				this.valueArr2 = []
				for (let i of dataList) {
					let dateStr = i.flowDateStr;
					this.titleArr2.push(dateStr);
					this.valueArr2.push(parseInt(i.usedFlow / 1024.0));
				}

				let dom = document.getElementById("totalPoolStatis");
				let myChart = echarts.init(dom);
				this.totalPoolStatis = myChart
				const option = {
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: this.titleArr2
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						/* tooltip:{
							// position:["50%","50%"],
							//trigger  : 'item',
							formatter:  "{a} <br/>{b}: {c} ({d}%)",
						}, */
						itemStyle: {
							normal: {
								label: {
									show: true
								}
							}

						},
						color: '#67A7DB',
						lineStyle: {
							color: '#67A7DB'
						},
						data: this.valueArr2,
						type: 'line',
						areaStyle: {}
					}]
				};

				if (option && typeof option === "object") {
					myChart.setOption(option, true);
				}
			},

			getStatisGps() {
				statisGps({}).then(res => {
					//this.tableData3 = res
					//this.loadchina_map("china", "中国")
				})
			},
			loadchina_map(pName, Chinese_) {
				var myChart = echarts.init(document.getElementById('china-map'));
				var tmpSeriesData = pName === "china" ? this.seriesData : [];

				var option = {
					/*title: {
					  text: Chinese_ || pName,
					  left: 'center'
					},*/
					tooltip: {
						trigger: 'item',
						formatter: function(value) {
							return "<font style='min-width: 20px;height: 20px;background: red;box-sizing: border-box;color: white;font-size: 10px;text-align: left;line-height: 20px;padding: 0 5px;border-radius: 10px;display: inline-block;position:relative;left: 1px;'>" +
								(parseInt(value.dataIndex) + 1) + "</font>&nbsp;&nbsp;" + value.data.name + " : " +
								value.data.count;
						}
					},
					visualMap: {
						show: true,
						type: 'piecewise',
						splitNumber: 7,
						pieces: [{
								value: 6,
								color: '#003399'
							}, {
								value: 5,
								color: '#0159B2'
							}, {
								value: 4,
								color: '#0072DF'
							}, {
								value: 3,
								color: '#1C8EFF'
							},
							{
								value: 2,
								color: '#50A8FF'
							}, {
								value: 1,
								color: '#83C0FF'
							}, {
								value: 0,
								color: '#ABD5FF'
							}
						],
						min: 0,
						max: 7,
						left: '2%',
						top: 'bottom',
						text: ['高', '低'],
						//dimension:2,
						seriesIndex: 0,
						calculable: false
					},
					series: [{
						name: Chinese_ || pName,
						type: 'map',
						mapType: pName,
						roam: false, //是否开启鼠标缩放和平移漫游
						data: tmpSeriesData,
						left: "5%",
						top: "10%", //组件距离容器的距离
						zoom: 1.1,
						selectedMode: 'single',

						label: {
							normal: {
								show: true, //显示省份标签
								textStyle: {
									color: "#969696"
								} //省份标签字体颜色
							},
							emphasis: { //对应的鼠标悬浮效果
								show: true,
								textStyle: {
									color: "#323232"
								}
							}
						},
						itemStyle: {
							normal: {
								borderWidth: .5, //区域边框宽度
								borderColor: '#0550c3', //区域边框颜色
								areaColor: "#4ea397", //区域颜色
							},

							emphasis: {
								borderWidth: .5,
								borderColor: '#4b0082',
								areaColor: "#FEB41C",
							}
						},
					}]

				};

				myChart.setOption(option);

				myChart.off("click");

				if (pName === "china") { // 全国时，添加click 进入省级
					myChart.on('click', function(param) {

					});
				} else { // 省份，添加双击 回退到全国    	 
					myChart.on("dblclick", function() {
						this.loadchina_map("china", "中国");
					});
				}
			}
		}

	}
</script>

<style>
	.main-home .rowone {
		display: flex;
		/* align-items: center; */
		justify-content: space-between;
		padding: 3px 0px;
	}

	/* .main-home .col-flex {
		display: flex;
		flex-direction: column;
		justify-content: center;
	} */

	.tip-main {
		margin-top: 18px;
	}

	.tip-view {
		display: inline-block;
		width: 18px;
		height: 18px;
		vertical-align: top;
		margin-top: 5px;
	}

	.tip-text1 {
		color: #676a6c;
	}

	.tip-text2 {
		color: #ff6b01;
	}

	.main-home .el-table table {
		box-sizing: border-box !important;
		border-top: .5px solid #e7e7e7 !important;
		border-left: .5px solid #e7e7e7 !important;
		;
	}

	.main-home .el-table td,
	.main-home .el-table th.is-leaf {
		border-right: .5px solid #e7e7e7 !important;
		border-bottom: .5px solid #e7e7e7 !important;
		background-color: #F8F8F8;
	}

	.main-home .el-table--small td,
	.main-home .el-table--small th {
		padding: 6px 0 !important;
		color: #676a6c !important;
	}

	.main-home .el-row {
		margin-bottom: 15px;
	}

	.right-button{
		position: absolute;
		top:9px;
		right: 50px;
	}
</style>
